<template>
  <section>
    <div class="richtext">
      <p>
        Metering pump is also called dosing pump or proportioning pump. It is a
        kind of special volume pump that can meet all kinds of strict
        technological process requirements. The flow can be step-less adjusted
        in the range of 0-100%. It is a special positive displacement pump used
        to transport liquid (especially corrosive liquid) and has been widely
        used in the seawater desalination industry. There are many
        classification methods for the desalination metering pumps.
      </p>
      <p><br /></p>
      <p>
        <strong
          >1. According to the flow part of the desalination metering
          pump</strong
        >
      </p>
      <p>
        (1) plunger and piston type (2) mechanical diaphragm type (3) hydraulic
        diaphragm type
      </p>
      <p><strong>2. According to the driving method</strong></p>
      <p>(1) motor (2)electromagnetic</p>
      <p><strong>3. According to work style</strong></p>
      <p>(1)reciprocating (2)rotary (3) gear</p>
      <p>
        <strong
          >4. According to the characteristics of the desalination metering
          pump</strong
        >
      </p>
      <p>
        (1)Extra-large base (2)Large base (3)Medium base (4)Small base
        (5)Microcomputer base
      </p>
      <p><br /></p>
      <p>
        Other classification methods: electric control type, air control type,
        heat preservation type, heating type, high viscosity type, etc.
      </p>
      <p><br /></p>
      <h2><strong>Structure of the Desalination Metering Pump</strong></h2>
      <p><br /></p>
      <p>
        The pump consists of three parts: motor, transmission box and cylinder.
      </p>
      <p><br /></p>
      <p>
        The transmission box component is composed of a worm gear mechanism, a
        stroke adjustment mechanism and a crank connecting rod mechanism. The
        high adjustment stroke is implemented by rotating the adjustment hand
        wheel, thereby changing the eccentricity of the moving shaft to achieve
        the purpose of changing the plunger (piston) stroke.
      </p>
      <p><br /></p>
      <p>
        Cylinder body parts are composed of pump head, suction valve group,
        discharge valve group, plunger and packing seal.
      </p>
      <p><br /></p>
      <h2>
        <strong>Working Principle of the Desalination Metering Pump</strong>
      </h2>
      <p><br /></p>
      <p>
        The motor drives the worm through the coupling and decelerates the main
        shaft and the eccentric wheel through the worm gear to rotate. The
        eccentric wheel drives the reciprocating motion in the sliding adjusting
        seat of the bow-shaped connecting rod. When the plunger moves to the
        rear dead center, a vacuum is gradually formed in the pump chamber. Once
        the suction valve opens, liquid will be sucked in. When the plunger
        moves to the forward dead center, the suction valve closes and the
        discharge valve opens, and the liquid moves further toward Exhausted
        during exercise. In the reciprocating and smooth operation of the pump,
        a continuous pressure and quantitative discharge liquid is formed.
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Desalination Metering Pump",
}
</script>